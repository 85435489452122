<template>
  <div>
    <Bar :chart-data="datacollection" :options="options" :class="'chart-container'"></Bar>
  </div>
</template>
<script>
import Bar from '@/components/charts/Bar'
import { mapGetters } from 'vuex'

export default {
  name: 'GroupBookingsChart',
  components: {
    Bar
  },
  props: {
    groupBookings: {
      type: Object,
      default: () => ({})
    },
    filterParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      datacollection: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            defaultFontSize: 13,
            fontColor: '#111517'
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              categoryPercentage: 0.5,
              fontColor: '#111517',
              fontSize: 13
            }
          ],
          yAxes: [
            {
              id: 'leftY',
              ticks: {
                beginAtZero: true,
                fontColor: '#111517',
                fontSize: 13,
                callback: function(value, index, values) {
                  return `${value} CHF`
                }
              }
            },
            {
              id: 'rightY',
              position: 'right',
              gridLines: {
                display: false
              },
              ticks: {
                stepSize: 5,
                beginAtZero: false,
                fontColor: '#111517',
                fontSize: 13
              }
            }
          ]
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    })
  },
  watch: {
    groupBookings: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.generateChartData()
        }
      }
    },
    siteLang(newVal) {
      if (newVal) {
        this.generateChartData()
      }
    }
  },
  methods: {
    generateChartLabels() {
      let labels = []

      this.groupBookings.forEach(item => {
        labels.push(item.key)
      })
      return labels
    },
    generateBarChartDataDataset(type) {
      let data = []

      this.groupBookings.forEach(item => {
        data.push(parseFloat(item.totalPrice.replace(/\,/g, ''), 10))
      })

      return data
    },
    generateLineChartDataDataset(type) {
      let data = []

      this.groupBookings.forEach(item => {
        data.push(item.totalBookings)
      })

      return data
    },
    generateChartData() {
      let labels = []
      let bar = []
      let line = []

      if (this.groupBookings) {
        labels = this.generateChartLabels()

        bar = this.generateBarChartDataDataset()
        line = this.generateLineChartDataDataset()
      }

      this.datacollection = {
        ...{ labels: labels },
        ...{
          datasets: [
            {
              yAxisID: 'leftY',
              type: 'bar',
              label: this.$t('revenue_reports.content.earnings'),
              backgroundColor: 'rgba(255, 107, 9, 0.5)',
              borderColor: '#ff6b09',
              hoverBackgroundColor: 'rgba(255, 107, 9, 0.8)',
              hoverBorderColor: 'ff6b09',
              borderWidth: 1,
              data: bar
            },
            {
              yAxisID: 'rightY',
              type: 'line',
              label: this.$t('revenue_reports.content.bookings'),
              fill: false,
              borderWidth: 2,
              backgroundColor: '#111517',
              borderColor: '#111517',
              pointBorderColor: '#111517',
              pointBackgroundColor: '#87898a',
              pointBorderWidth: 5,
              lineTension: 0.5,
              data: line
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
