<template>
  <div>
    <Bar :chart-data="datacollection" :options="options" :class="'chart-container'"></Bar>
  </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

import Bar from '@/components/charts/Bar'

export default {
  name: 'OverviewChart',
  components: {
    Bar
  },
  props: {
    overview: {
      type: Object,
      default: () => ({})
    },
    filterParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      datacollection: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            defaultFontSize: 13,
            fontColor: '#111517'
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              categoryPercentage: 0.5,
              fontColor: '#111517',
              fontSize: 13
            }
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
                fontColor: '#111517',
                fontSize: 13,

                callback: function(value, index, values) {
                  return `${value} CHF`
                }
              }
            }
          ]
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    })
  },
  watch: {
    overview: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.generateChartData()
        }
      }
    },
    siteLang(newVal) {
      if (newVal) {
        this.generateChartData()
      }
    }
  },
  methods: {
    generateChartLabels() {
      let labels = []

      if (this.filterParams.period === 'year') {
        labels = moment.localeData(this.siteLang).monthsShort()
      }
      if (this.filterParams.period === 'month') {
        const daysInMonth = moment(this.filterParams.date).daysInMonth()
        const daysNumbersArray = Array.from({ length: daysInMonth }, (v, k) => k + 1)

        daysNumbersArray.forEach(number => {
          labels.push(number)
        })
      }
      if (this.filterParams.period === 'day') {
        new Array(24).fill().forEach((acc, index) => {
          labels.push(moment({ hour: index }).format('HH:mm'))
        })
      }

      return labels
    },
    generateChartDataDatasetByTypeAndLabels(type, labels) {
      let data = []

      labels.forEach(label => {
        let hasTotal = false
        if (this.filterParams.period === 'year') {
          moment.locale(this.siteLang)

          let labelMonthKey = moment()
            .month(label)
            .format('M')

          this.overview[type].forEach(item => {
            moment.locale('en')
            let dataMonthKey = moment()
              .month(item.key)
              .format('M')

            if (labelMonthKey === dataMonthKey) {
              data.push(parseFloat(item.total.replace(/\,/g, ''), 10))
              hasTotal = true
            }
          })
        }

        if (this.filterParams.period === 'month') {
          this.overview[type].forEach(item => {
            if (label === Number(moment(item.key).format('DD'))) {
              data.push(parseFloat(item.total.replace(/\,/g, ''), 10))
              hasTotal = true
            }
          })
        }

        if (this.filterParams.period === 'day') {
          this.overview[type].forEach(item => {
            if (label === item.key) {
              data.push(parseFloat(item.total.replace(/\,/g, ''), 10))
              hasTotal = true
            }
          })
        }

        if (!hasTotal) {
          data.push(0)
        }
      })

      return data
    },
    generateChartData() {
      let labels = []
      let earningsData = []
      let cancelledData = []

      if (this.overview) {
        labels = this.generateChartLabels()

        earningsData = this.generateChartDataDatasetByTypeAndLabels('earnings', labels)
        cancelledData = this.generateChartDataDatasetByTypeAndLabels('cancelled', labels)
      }

      this.datacollection = {
        ...{ labels: labels },
        ...{
          datasets: [
            {
              label: this.$t('revenue_reports.content.earnings'),
              backgroundColor: 'rgba(255, 107, 9, 0.5)',
              borderColor: '#ff6b09',
              hoverBackgroundColor: 'rgba(255, 107, 9, 0.8)',
              hoverBorderColor: 'ff6b09',
              borderWidth: 1,
              data: earningsData
            },
            {
              label: this.$t('revenue_reports.content.cancelled'),
              backgroundColor: 'rgba(141, 159, 167, 0.5)',
              borderColor: '#8d9fa7',
              hoverBackgroundColor: 'rgba(141, 159, 167, 0.8)',
              hoverBorderColor: '8d9fa7',
              borderWidth: 1,
              data: cancelledData
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
