<template>
  <div class="row">
    <div v-for="(specialist, key) in staffList" :key="`staff-${key}`" class="col-md-4 col-12 mb-1-5">
      <div class="item border pt-1-5 pt-md-2-5 text-center bg-white staff-card-bg">
        <div>
          <img v-if="specialist.avatar" :src="specialist.avatar" class="staff-card-img mb-1-5" alt />
          <img v-else src="@/assets/images/default/default_avatar.png" class="staff-card-img mb-1-5" alt />

          <h6 class="text-black poppins-font mb-1-5 mb-md-2-5">
            {{ specialist.name }}
          </h6>
        </div>

        <table class="table table-bordered m-0">
          <thead class="table-bordered">
            <tr>
              <th class="text-small roboto-font font-weight-medium py-1-5 pl-1-5 pr-1 text-center">
                {{ $t('revenue_reports.content.rating') }}
              </th>
              <th class="text-small roboto-font font-weight-medium py-1-5 pl-1-5 pr-1 text-center">
                {{ $t('revenue_reports.content.bookings') }}
              </th>
              <th class="text-small roboto-font font-weight-medium py-1-5 pl-1-5 pr-1 text-center">
                {{ $t('revenue_reports.content.earnings') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-bottom-0">
              <td class="py-1-5 px-1-5">{{ specialist.rating }}</td>
              <td class="py-1-5 px-1-5">{{ specialist.bookings }}</td>
              <td class="py-1-5 px-1-5">{{ specialist.earnings }} CHF</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StaffSection',
  props: {
    staffList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped></style>
