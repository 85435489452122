import { render, staticRenderFns } from "./GroupBookingsChart.vue?vue&type=template&id=7235067b&scoped=true&"
import script from "./GroupBookingsChart.vue?vue&type=script&lang=js&"
export * from "./GroupBookingsChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7235067b",
  null
  
)

export default component.exports