<template>
  <div class="row mb-1-5">
    <div class="col-12 col-md-3">
      <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">
        {{ $t('revenue_reports.content.filter_by') }}
      </label>
      <Multiselect
        v-model="selected.period"
        :options="periodFilterData"
        label="label"
        :searchable="false"
        :select-label="''"
        :hide-selected="false"
        :allow-empty="false"
        :placeholder="$t('revenue_reports.content.select_option')"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title">{{ $t(props.option.i18KeyLabel) }}</span>
          </span>
        </template>
        <template slot="caret" slot-scope="{ option, toggle }">
          <div
            class="multiselect__select d-flex align-items-center justify-content-center"
            @mousedown.prevent.stop="toggle()"
          >
            <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">{{ $t(props.option.i18KeyLabel) }}</span>
          </div>
        </template>
      </Multiselect>
    </div>
    <div v-if="showYearSelect" class="col-12 col-md-3">
      <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">
        {{ $t('revenue_reports.content.choose_year') }}
      </label>
      <Multiselect
        v-model="selected.year"
        :options="yearsFilterData"
        label="label"
        :searchable="false"
        :select-label="''"
        :hide-selected="false"
        :allow-empty="false"
        :placeholder="$t('revenue_reports.content.select_option')"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title">{{ $t(props.option.i18KeyLabel) }}</span>
          </span>
        </template>
        <template slot="caret" slot-scope="{ option, toggle }">
          <div
            class="multiselect__select d-flex align-items-center justify-content-center"
            @mousedown.prevent.stop="toggle()"
          >
            <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">{{ $t(props.option.i18KeyLabel) }}</span>
          </div>
        </template>
      </Multiselect>
    </div>
    <div v-if="showMonthSelect" class="col-12 col-md-3">
      <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">
        {{ $t('revenue_reports.content.choose_month') }}
      </label>
      <Multiselect
        v-model="selected.month"
        :options="monthsFilterData"
        label="label"
        :searchable="false"
        :select-label="''"
        :hide-selected="false"
        :allow-empty="false"
        :placeholder="$t('revenue_reports.content.select_option')"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title">{{ $t(props.option.i18KeyLabel) }}</span>
          </span>
        </template>
        <template slot="caret" slot-scope="{ option, toggle }">
          <div
            class="multiselect__select d-flex align-items-center justify-content-center"
            @mousedown.prevent.stop="toggle()"
          >
            <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">{{ $t(props.option.i18KeyLabel) }}</span>
          </div>
        </template>
      </Multiselect>
    </div>
    <div v-if="showDaySelect" class="col-12 col-md-3">
      <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">
        {{ $t('revenue_reports.content.choose_day') }}
      </label>
      <Multiselect
        v-model="selected.day"
        :options="daysFilterData"
        label="label"
        :searchable="false"
        :select-label="''"
        :hide-selected="false"
        :allow-empty="false"
        :placeholder="$t('revenue_reports.content.select_option')"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title">{{ $t(props.option.i18KeyLabel) }}</span>
          </span>
        </template>
        <template slot="caret" slot-scope="{ option, toggle }">
          <div
            class="multiselect__select d-flex align-items-center justify-content-center"
            @mousedown.prevent.stop="toggle()"
          >
            <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">{{ $t(props.option.i18KeyLabel) }}</span>
          </div>
        </template>
      </Multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ProductFilter',
  components: { Multiselect },
  props: {
    storeActionName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: {
        period: {
          i18KeyLabel: 'revenue_reports.filter_options.all_time',
          value: 'all_time'
        },
        year: null,
        month: null,
        day: null
      },
      periodFilterData: [
        {
          i18KeyLabel: 'revenue_reports.filter_options.all_time',
          value: 'all_time'
        },
        {
          i18KeyLabel: 'revenue_reports.filter_options.year',
          value: 'year'
        },
        {
          i18KeyLabel: 'revenue_reports.filter_options.month',
          value: 'month'
        },
        {
          i18KeyLabel: 'revenue_reports.filter_options.day',
          value: 'day'
        }
      ],
      selectedCategory: null,
      filtersApplied: false
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    }),
    yearsFilterData() {
      const years = []
      const dateStart = moment([2020])
      const dateEnd = moment([moment().format('YYYY')])

      while (dateEnd.diff(dateStart, 'years') >= 0) {
        const item = {
          i18KeyLabel: dateStart.format('YYYY'),
          value: dateStart.format('YYYY')
        }
        years.push(item)
        dateStart.add(1, 'year')
      }

      return years
    },
    monthsFilterData() {
      const monthList = []

      moment
        .localeData(this.siteLang)
        .months()
        .forEach(month => {
          const item = {
            i18KeyLabel: month,
            value: moment()
              .month(month)
              .format('MM')
          }

          monthList.push(item)
        })

      return monthList
    },
    daysFilterData() {
      const days = []

      if (this.selected.year && this.selected.month) {
        const daysInMonth = moment([this.selected.year.value, Number(this.selected.month.value - 1)]).daysInMonth()
        const daysNumbersArray = Array.from({ length: daysInMonth }, (v, k) => k + 1)

        daysNumbersArray.forEach(number => {
          let value = number
          if (value < 10) {
            value = `0${number}`
          }

          const item = {
            i18KeyLabel: number,
            value: value
          }

          days.push(item)
        })
      }

      return days
    },
    showYearSelect() {
      const periods = ['year', 'month', 'day']

      return periods.includes(this.selected.period.value)
    },
    showMonthSelect() {
      const periods = ['month', 'day']

      return periods.includes(this.selected.period.value)
    },
    showDaySelect() {
      const periods = ['day']

      return periods.includes(this.selected.period.value)
    }
  },
  watch: {
    'selected.period.value': {
      handler(newVal) {
        if (newVal === 'all_time') {
          this.selected.year = null
          this.selected.month = null
          this.selected.day = null
        }

        if (newVal === 'year') {
          this.selected.month = null
          this.selected.day = null
        }

        if (newVal === 'month') {
          this.selected.day = null
        }
      }
    },
    selected: {
      deep: true,
      handler(newVal) {
        if (newVal.period) {
          if (newVal.period.value === 'all_time') {
            this.applyFiltersEmit('all_time')
          }

          this.filterByCondition(newVal.period.value)
        }
      }
    }
  },
  mounted() {
    if (this.storeActionName === 'fetchOverview') {
      this.periodFilterData.shift()
      this.selected.period = {
        i18KeyLabel: 'revenue_reports.filter_options.year',
        value: 'year'
      }

      this.selected.year = {
        i18KeyLabel: moment().format('YYYY'),
        value: moment().format('YYYY')
      }
    } else {
      this.applyFiltersEmit('all_time')
    }
  },
  methods: {
    filterByCondition(type) {
      if (this.selected[type] && this.selected.period.value === type) {
        this.applyFiltersEmit(type)
      }
    },
    paramsByType(type) {
      let params = {}

      if (type === 'year') {
        params = {
          ...params,
          ...{
            day_from: `${this.selected.year.value}-01-01`,
            day_to: `${this.selected.year.value}-12-31`
          }
        }
      }

      if (type === 'month') {
        let startDate = `${this.selected.year.value}-${this.selected.month.value}-01`
        let endDate = moment(startDate)
          .endOf('month')
          .format('YYYY-MM-DD')

        params = {
          ...params,
          ...{
            day_from: startDate,
            day_to: endDate
          }
        }
      }

      if (type === 'day') {
        let date = `${this.selected.year.value}-${this.selected.month.value}-${this.selected.day.value}`

        params = {
          ...params,
          ...{
            day_from: date,
            day_to: date
          }
        }
      }

      return params
    },
    applyFiltersEmit(type) {
      let params = {
        type: type,
        action: this.storeActionName,
        values: this.paramsByType(type)
      }

      this.$emit('apply-filters', params)
    }
  }
}
</script>

<style lang="scss" scoped></style>
