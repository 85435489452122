<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  watch: {
    chartData: {
      handler(newVal) {
        this.$data._chart.update()
      }
    }
  },
  mounted() {
    this.render()
  },
  methods: {
    render() {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>
