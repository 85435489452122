<template>
  <div class="position-relativew">
    <Doughnut :chart-data="datacollection" :options="options" :class="'chart-container'"></Doughnut>
    <div id="empty-client-data">
      {{ $t('revenue_reports.content.nothing_to_display') }}
    </div>
  </div>
</template>
<script>
import Doughnut from '@/components/charts/Doughnut'
import { mapGetters } from 'vuex'

export default {
  name: 'ClientChart',
  components: {
    Doughnut
  },
  props: {
    clients: {
      type: Object,
      default: () => ({})
    },
    filterParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      datacollection: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 60,
        animation: {
          onComplete: function(animation) {
            if (animation.chart.config.data.datasets.length) {
              let firstSet = animation.chart.config.data.datasets[0].data
              let dataSum = firstSet.reduce((accumulator, currentValue) => accumulator + currentValue)

              if (typeof firstSet !== 'object' || dataSum === 0) {
                document.getElementById('empty-client-data').style.display = 'block'
              } else {
                document.getElementById('empty-client-data').style.display = 'none'
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    })
  },
  watch: {
    clients: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.generateChartData()
        }
      }
    },
    siteLang(newVal) {
      if (newVal) {
        this.generateChartData()
      }
    }
  },
  methods: {
    generateChartData() {
      let labels = [
        this.$t('revenue_reports.content.returning_clients'),
        this.$t('revenue_reports.content.new_clients')
      ]
      let data = [0,0]

      if (this.clients) {
        data = [this.clients.returnClients, this.clients.newClients]
      }

      this.datacollection = {
        ...{ labels: labels },
        ...{
          datasets: [
            {
              backgroundColor: ['rgba(255, 107, 9, 0.5)', 'rgba(255, 179, 0, 0.5)'],
              hoverBackgroundColor: ['rgba(255, 107, 9, 0.8)', 'rgba(255, 179, 0, 0.8)'],
              borderColor: ['#ff6b09', '#ffb300'],
              hoverBorderColor: ['#ff6b09', '#ffb300'],
              borderWidth: 1,
              data: data
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
