<template>
  <div class="review-rating-card d-flex flex-column item border p-1-5 p-md-2-5 text-center bg-white rating-card-bg">
    <h6 class="text-black poppins-font">
      {{ $t('revenue_reports.content.all_time_rating') }}
    </h6>

    <div class="d-flex m-auto">
      <span class="poppins-font fz-base">
        <span class="h1">{{ rating }}</span>
        /5
      </span>
      <div class="ml-2">
        <FaRating
          :glyph="iconStar"
          :border-width="0"
          :active-color="'#ff6b09'"
          :inactive-color="'#FFE1CD'"
          :show-rating="false"
          :item-size="18"
          :read-only="true"
          :increment="0.01"
          :rating="rating"
          :max-rating="5"
          :spacing="3"
        ></FaRating>

        <span class="text-gray fz-base font-weight-normal">
          {{ $t('revenue_reports.content.feedback_rating_count', { count: countReviews }) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { FaRating } from 'vue-rate-it'
import Star from 'vue-rate-it/glyphs/star'

export default {
  name: 'ReviewsRating',
  components: { FaRating },
  props: {
    rating: {
      type: Number,
      default: 0
    },
    countReviews: {
      type: Number,
      default: 0
    }
  },
  created() {
    this.iconStar = Star
  }
}
</script>

<style lang="scss" scoped></style>
