<template>
  <div>
    <HorizontalBar :chart-data="datacollection" :options="options" :class="'chart-container'"></HorizontalBar>
  </div>
</template>
<script>
import HorizontalBar from '@/components/charts/HorizontalBar'
import { mapGetters } from 'vuex'

export default {
  name: 'GroupBookingsChart',
  components: {
    HorizontalBar
  },
  props: {
    salonRating: {
      type: Object,
      default: () => ({})
    },
    filterParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      datacollection: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: false,
              stacked: true
            }
          ],
          yAxes: [
            {
              id: 'rightY1',
              ticks: {
                beginAtZero: true,
                fontColor: '#111517',
                stepSize: 1,
                fontSize: 13,
                callback: (value, index, values) => {
                  return `${value} ${this.$t('revenue_reports.content.star')}`
                }
              }
            },
            {
              id: 'rightY2',
              position: 'right',
              offset: true,
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: false,
                fontColor: '#111517',
                fontSize: 13,
                min: 1,
                max: 5,
                stepSize: 1,
                padding: 25,
                lineHeight: 3.5,
                callback: (value, index, values) => {
                  if (this.salonRating.rating) {
                    let data = JSON.parse(JSON.stringify(this.salonRating.rating)).reverse()

                    return data[index]
                  } else {
                    return 0
                  }
                }
              }
            }
          ]
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    })
  },
  watch: {
    salonRating: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.generateChartData()
        }
      }
    },
    siteLang(newVal) {
      if (newVal) {
        this.generateChartData()
      }
    }
  },
  methods: {
    generateChartData() {
      let labels = [5, 4, 3, 2, 1]
      let data = []

      if (this.salonRating) {
        if (this.salonRating.rating) {
          data = JSON.parse(JSON.stringify(this.salonRating.rating)).reverse()
        }
      }

      this.datacollection = {
        ...{ labels: labels },
        ...{
          datasets: [
            {
              label: '',
              yAxisID: 'rightY1',
              backgroundColor: 'rgba(255, 107, 9, 0.5)',
              borderColor: '#ff6b09',
              hoverBackgroundColor: 'rgba(255, 107, 9, 0.8)',
              hoverBorderColor: 'ff6b09',
              borderWidth: 1,
              data: data
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
