<template>
  <div>
    <Doughnut :chart-data="datacollection" :options="options" :class="'chart-container'"></Doughnut>
    <div id="empty-booking-data">
      {{ $t('revenue_reports.content.nothing_to_display') }}
    </div>
  </div>
</template>
<script>
import Doughnut from '@/components/charts/Doughnut'
import { mapGetters } from 'vuex'

export default {
  name: 'BookingsChart',
  components: {
    Doughnut
  },
  props: {
    bookings: {
      type: Object,
      default: () => ({})
    },
    filterParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      datacollection: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 60,
        animation: {
          onComplete: function(animation) {
            if (animation.chart.config.data.datasets.length) {
              let firstSet = animation.chart.config.data.datasets[0].data
              let dataSum = firstSet.reduce((accumulator, currentValue) => accumulator + currentValue)

              if (typeof firstSet !== 'object' || dataSum === 0) {
                document.getElementById('empty-booking-data').style.display = 'block'
              } else {
                document.getElementById('empty-booking-data').style.display = 'none'
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    })
  },
  watch: {
    bookings: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.generateChartData()
        }
      }
    },
    siteLang(newVal) {
      if (newVal) {
        this.generateChartData()
      }
    }
  },
  methods: {
    generateChartData() {
      let labels = [this.$t('revenue_reports.content.success'), this.$t('revenue_reports.content.cancelled')]
      let data = [0,0]

      if (this.bookings) {
        data = [this.bookings.successBookings, this.bookings.cancelledBookings]
      }

      this.datacollection = {
        ...{ labels: labels },
        ...{
          datasets: [
            {
              backgroundColor: ['rgba(255, 107, 9, 0.5)', 'rgba(141, 159, 167, 0.5)'],
              hoverBackgroundColor: ['rgba(255, 107, 9, 0.8)', 'rgba(141, 159, 167, 0.8)'],
              borderColor: ['#ff6b09', '#8d9fa7'],
              hoverBorderColor: ['#ff6b09', '#8d9fa7'],
              borderWidth: 1,
              data: data
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
