<template>
  <div class="navbar-offset-tablet salon-flow">
    <PageNavigator :page-title="$t('revenue_reports.breadcrumbs-list-label')" :has-back="false" :class="'py-0-8'">
      <template v-slot:action>
        <div class="py-1-5"></div>
      </template>
    </PageNavigator>

    <div class="pt-7-5">
      <div class="row salon-report-list mb-1-5 px-1-5 pb-1-5 border-bottom">
        <div class="col-12">
          <ReportFilter :store-action-name="'fetchTotal'" @apply-filters="fetchDataByParams"></ReportFilter>
        </div>

        <div class="col-12 col-md-4 mb-1-5 mb-md-0">
          <div class="item border p-1-5 p-md-3 text-center total-card-bg">
            <h6 class="text-black poppins-font mb-2">
              {{ $t('revenue_reports.content.earnings') }}
              <span class="text-gray">{{ getPeriodTitleByFilterName('total') }}</span>
            </h6>
            <p class="text-black mb-0">{{ total.totalSum }} CHF</p>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-1-5 mb-md-0">
          <div class="item border p-1-5 p-md-3 text-center total-card-bg">
            <h6 class="text-black poppins-font mb-2">
              {{ $t('revenue_reports.content.bookings') }}
              <span class="text-gray">{{ getPeriodTitleByFilterName('total') }}</span>
            </h6>
            <p class="text-black mb-0">{{ total.totalBookings }}</p>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-1-5 mb-md-0">
          <div class="item border p-1-5 p-md-3 text-center total-card-bg">
            <h6 class="text-black poppins-font mb-2">
              {{ $t('revenue_reports.content.average_selling_price') }}
              <span class="text-gray">{{ getPeriodTitleByFilterName('total') }}</span>
            </h6>
            <p class="text-black mb-0">{{ total.totalAverage }} CHF</p>
          </div>
        </div>
      </div>

      <div class="row mb-1-5 px-1-5 pb-1-5 border-bottom">
        <div class="col-12">
          <p class="text-black mb-1-5 poppins-font font-weight-medium">
            {{ $t('revenue_reports.content.overview') }}
          </p>
          <ReportFilter :store-action-name="'fetchOverview'" @apply-filters="fetchDataByParams"></ReportFilter>
        </div>

        <div class="col-12 position-relative text-center salon-report-list">
          <div class="item border p-1-5 py-md-2-5 text-center bg-white bg-img-none">
            <h6 class="text-black poppins-font">
              {{ $t('revenue_reports.content.earnings') }}
              <span class="text-gray">{{ getPeriodTitleByFilterName('overview') }}</span>
            </h6>
            <OverviewChart :overview="overview" :filter-params="filterParams.overview"></OverviewChart>
          </div>
        </div>
      </div>

      <div class="row mb-1-5 px-1-5 pb-1-5 border-bottom">
        <div class="col-12">
          <p class="text-black mb-1-5 poppins-font font-weight-medium">
            {{ $t('revenue_reports.content.earnings_and_bookings_by_service') }}:
          </p>
          <ReportFilter :store-action-name="'fetchByService'" @apply-filters="fetchDataByParams"></ReportFilter>
        </div>

        <div class="col-12 position-relative text-center salon-report-list">
          <div class="item border p-1-5 py-md-2-5 text-center bg-white bg-img-none">
            <h6 class="text-black poppins-font">
              {{ $t('revenue_reports.content.earnings_and_bookings_by_service') }}
              <span class="text-gray">{{ getPeriodTitleByFilterName('groupBookings') }}</span>
            </h6>
            <GroupBookingsChart
              :group-bookings="groupBookings"
              :filter-params="filterParams.overview"
            ></GroupBookingsChart>
          </div>
        </div>
      </div>

      <div class="row mb-1-5 px-1-5 pb-1-5 border-bottom">
        <div class="col-12">
          <p class="text-black mb-1-5 poppins-font font-weight-medium">
            {{ $t('revenue_reports.content.client_success') }}
          </p>
          <ReportFilter :store-action-name="'fetchClients'" @apply-filters="fetchDataByParams"></ReportFilter>
        </div>
        <div class="col-12 col-md-6 position-relative text-center salon-report-list mb-1-5 mb-md-0">
          <div class="item border p-1-5 py-md-2-5 text-center bg-white bg-img-none">
            <h6 class="text-black poppins-font">
              {{ $t('revenue_reports.content.clients') }}
              <span class="text-gray">{{ getPeriodTitleByFilterName('clients') }}</span>
            </h6>
            <ClientChart :clients="clients" :filter-params="filterParams.clients"></ClientChart>
          </div>
        </div>

        <div class="col-12 col-md-6 position-relative text-center salon-report-list">
          <div class="item border p-1-5 py-md-2-5 text-center bg-white bg-img-none">
            <h6 class="text-black poppins-font">
              {{ $t('revenue_reports.content.booking_success') }}
              <span class="text-gray">{{ getPeriodTitleByFilterName('clients') }}</span>
            </h6>
            <BookingsChart :bookings="bookings" :filter-params="filterParams.bookings"></BookingsChart>
          </div>
        </div>
      </div>

      <div class="row salon-report-list mb-2-5 px-1-5">
        <div class="col-12">
          <p class="text-black mb-1-5 poppins-font font-weight-medium">
            {{ $t('revenue_reports.content.staff_performance') }}
          </p>
          <ReportFilter :store-action-name="'fetchStaffs'" @apply-filters="fetchDataByParams"></ReportFilter>
        </div>
        <div class="col-12">
          <StaffSection :staff-list="staff.data"></StaffSection>
        </div>
      </div>

      <div class="row mb-1-5 px-1-5 pb-1-5 border-bottom">
        <div class="col-12">
          <CustomPagination
            :data="staff"
            :show-disabled="true"
            @pagination-change-page="changeStaffPaginationPage"
          ></CustomPagination>
        </div>
      </div>

      <div class="row mb-1-5 mb-md-2-5 salon-report-list px-1-5">
        <div class="col-12">
          <p class="text-black mb-1-5 poppins-font font-weight-medium">
            {{ $t('revenue_reports.content.salon_ratings') }}
          </p>
        </div>
        <div class="col-12 col-md-7 mb-1-5 mb-md-0">
          <div class="item border pl-1-5 text-center bg-white bg-img-none">
            <RatingChart :salon-rating="salonRating"></RatingChart>
          </div>
        </div>
        <div class="col-12 col-md-5">
          <ReviewsRating :rating="formatedRating" :count-reviews="formatedCountReviews"></ReviewsRating>
        </div>
      </div>

      <div class="row">
        <div class="col-12 py-1-5 text-center top-shadow bg-white">
          <p class="mb-1 fz-base text-black poppins-font">
            {{ $t('revenue_reports.content.all_time_quiff_bookings') }}
            <span class="text-primary font-weight-semibold">{{ total.totalBookings }}</span>
          </p>
          <p
            class="mb-0 fz-base font-weight-semibold text-black poppins-font"
            v-html="$t('revenue_reports.content.total_summary', { total: total.totalSum })"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import CustomPagination from '@/components/CustomPagination'
import ReportFilter from '@/components/filters/ReportFilter'

import moment from 'moment'
import { mapGetters, mapActions, mapMutations } from 'vuex'

import OverviewChart from '@/components/reports/OverviewChart'
import GroupBookingsChart from '@/components/reports/GroupBookingsChart'
import ClientChart from '@/components/reports/ClientChart'
import BookingsChart from '@/components/reports/BookingsChart'
import StaffSection from '@/components/reports/StaffSection'
import RatingChart from '@/components/reports/RatingChart'
import ReviewsRating from '@/components/reports/ReviewsRating'

export default {
  name: 'RevenueReports',
  components: {
    PageNavigator,
    ReportFilter,
    OverviewChart,
    GroupBookingsChart,
    ClientChart,
    BookingsChart,
    StaffSection,
    CustomPagination,
    RatingChart,
    ReviewsRating
  },
  data() {
    return {
      filterParams: {
        total: {
          period: 'all_time',
          date: null,
          payloadData: null
        },
        groupBookings: {
          period: 'all_time',
          date: null,
          payloadData: null
        },
        clients: {
          period: 'all_time',
          date: null,
          payloadData: null
        },
        bookings: {
          period: 'all_time',
          date: null,
          payloadData: null
        },
        staff: {
          period: 'all_time',
          date: null,
          payloadData: null
        },
        salonRating: {
          period: 'all_time',
          date: null,
          payloadData: null
        },
        overview: {
          period: 'year',
          date: null,
          payloadData: null
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      total: 'reports/getTotal',
      groupBookings: 'reports/getGroupBookings',
      clients: 'reports/getClients',
      bookings: 'reports/getBookings',
      staff: 'reports/getStaff',
      salonRating: 'reports/getSalonRating',
      overview: 'reports/getOverview'
    }),
    salonId() {
      return this.accountInfo.salon_id
    },
    formatedRating() {
      return this.salonRating.total ? Number(this.salonRating.total) : 0
    },
    formatedCountReviews() {
      return this.salonRating.reviews ? Number(this.salonRating.reviews) : 0
    }
  },
  mounted() {
    this.resetReportState()
    this.fetchSalonRating({ salon_id: this.salonId })
  },
  beforeRouteLeave(to, from, next) {
    this.resetReportState()
    next()
  },
  methods: {
    ...mapActions({
      fetchTotal: 'reports/getTotal',
      fetchByService: 'reports/getByService',
      fetchClients: 'reports/getClient',
      fetchBookings: 'reports/getBookings',
      fetchStaffs: 'reports/getStaffs',
      fetchSalonRating: 'reports/getSalonRating',
      fetchOverview: 'reports/getOverview'
    }),
    ...mapMutations({
      resetReportState: 'reports/RESET_STATE'
    }),
    getPeriodTitleByFilterName(filterName) {
      let filter = this.filterParams[filterName]

      if (filter.period === 'year') {
        return moment(filter.date).format('YYYY')
      } else if (filter.period === 'month') {
        return moment(filter.date).format('MMM, YYYY')
      } else if (filter.period === 'day') {
        return moment(filter.date).format('MMM D, YYYY')
      }

      return this.$t('revenue_reports.filter_options.all_time')
    },
    fetchDataByParams(params) {
      let payload = {
        salon_id: this.salonId,
        ...params.values
      }

      let actions = [params.action]

      if (params.action === 'fetchClients') {
        actions.push('fetchBookings')
      }

      if (params.action == 'fetchOverview') {
        payload = {
          ...payload,
          ...{ group_param: params.type }
        }
      }

      this.setFilterPeriodParams(params.action, params.type, payload)

      actions.forEach(name => {
        this[name](payload)
          .then(result => {})
          .catch(errors => {})
      })
    },
    setFilterPeriodParams(action, type, params) {
      const actions = {
        fetchTotal: 'total',
        fetchByService: 'groupBookings',
        fetchClients: 'clients',
        fetchBookings: 'bookings',
        fetchStaffs: 'staff',
        fetchSalonRating: 'salonRating',
        fetchOverview: 'overview'
      }

      this.filterParams[actions[action]].period = type
      this.filterParams[actions[action]].payloadData = params

      if (params.day_from) {
        this.filterParams[actions[action]].date = params.day_from
      } else {
        this.filterParams[actions[action]].date = null
      }
    },
    changeStaffPaginationPage(page = 1) {
      let payloadData = this.filterParams.staff.payloadData
      payloadData.page = page

      this.fetchStaffs(payloadData)
        .then(result => {})
        .catch(errors => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
